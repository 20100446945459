import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IContact } from '../models/contact';
import { ISampleObject } from '../models/object';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  private requestService = inject(RequestService);

  getMenuChild(): Observable<{ department_service_levels: ISampleObject[] }> {
    return this.requestService.getJSON('/client/landing-page-cms/navigation-service-level');
  }

  getContact(): Observable<IContact> {
    return this.requestService.getJSON('/client/landing-page-cms/contact-us');
  }
}
